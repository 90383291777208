@import 'src/styles/imports';

.onboardingContainer {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .imageContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
  }

  .main {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 8rem 0;
    align-items: center;
    color: var(--global-text-color);
    gap: 2rem;
    position: relative;
    opacity: 0;
    transform: translateY(20px); /* Move down initially */
    animation: fadeIn 0.5s ease-out 0.3s forwards;

    .textContainer {
      width: 100%;
      max-width: 800px;
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      justify-content: center;
      gap: 1rem;
      text-align: center;
      margin: 0 auto;
      padding: 2rem;
      z-index: 1;
      .title {
        font-size: 2.4rem;
        letter-spacing: -0.6px;
        line-height: 120%;
        font-weight: 700;
      }
    }

    .contentContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      width: 100%;
      gap: 1rem;
      text-align: center;
      height: 100%;
      .description {
        max-width: 800px;
        padding: 0 2rem;
        z-index: 1;
      }
    }

    .animationContainer {
      height: 100%;
      z-index: 0;
      &.medium {
        width: 120%;
      }
      &.small {
        width: 200%;
      }
    }

    .optionContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      margin: 4rem;
      width: 100%;
      .flipped {
        transform: scaleX(-1);
      }
      .options {
        display: flex;
        gap: 2rem;
        width: 60%;
        .option {
          padding: 6rem 4rem;
          font-size: 1.6rem;
          font-weight: 700;
          background-repeat: no-repeat;
          position: relative;
        }
        &.mobile {
          flex-direction: column;
          width: 80%;
        }
      }

      .option {
        cursor: pointer;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 1rem;
        border: 3px solid var(--slate-slate-100, #e8eef4);
        background: var(--slate-slate-50, #f8fafc);
      }
      .selected {
        border: 3px solid var(--slate-slate-500, #a1b6ca);
        background: var(--card-cta-bg-color);
        color: var(--card-cta-text-color);
      }
    }
  }
}
.center {
  justify-content: center;
  .main {
    justify-content: center;
    color: white;
    .animationContainer {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100vw;
      height: calc(100vh - 68px);
      display: flex;
      justify-content: center;
      &.medium {
        width: 200%;
      }
      &.small {
        width: 400%;
      }
    }
    .textContainer {
      padding: 0;
      .title {
        font-size: 4rem;
      }
    }
  }
}
.navContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
  z-index: 1;
  white-space: nowrap;
  padding: 0 2rem;
  .actionButton {
    background-color: var(--card-cta-bg-color);
    color: var(--card-cta-text-color);
    border: none;
    height: 46px;
    padding: 10px 40px;
    display: inline-flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    font-size: 1.6rem;
    line-height: normal;
    font-weight: 500;
    transition: background-color 0.2s;
    border-radius: 44px;
    cursor: pointer;
    &.active {
      opacity: 0;
      animation: fadeIn 0.5s ease-out 1.2s forwards;
    }
    &:hover {
      background-color: var(--card-cta-active-bg-color);
    }

    &.text {
      color: var(--card-cta-bg-color);
      background-color: transparent;
      border: none;

      &:hover {
        background-color: var(--card-active-bg-color);
      }
    }

    &.iconLeft {
      svg {
        margin-left: -12px;
      }
    }
    &.iconRight {
      flex-direction: row-reverse;
      svg {
        margin-right: -12px;
      }
    }
    &:disabled {
      background-color: var(--card-cta-disabled-bg-color);
      color: var(--card-cta-disabled-text-color);
      cursor: no-drop !important;
      pointer-events: none;
    }
    &.firstPage {
      background-color: var(--card-cta-text-color);
      color: var(--card-cta-bg-color);
    }
  }
  &.mobile {
    width: 100%;
    .actionButton {
      height: auto;
      padding: 8px 24px;
    }
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Start lower */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Move to original position */
  }
}
