@import 'src/styles/imports';

.onboardingContainer {
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: background-color 0.3s ease;

  .navContainer {
    padding: 2rem 8rem;
    @media screen and (max-width: $mobile-layout-width) {
      padding: 2rem 2rem;
    }
  }
  &.firstPage {
    background-color: #1a477a;
    transition: background-color 0.3s ease;
    color: white;
    .navContainer {
      svg {
        pointer-events: none;
        display: block;
        path {
          fill: var(--canvas-text-color);
        }
      }
    }
  }
}
