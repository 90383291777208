.pageItem {
  display: flex;
  gap: 0.4rem;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 1.6rem;
  font-size: 1.6rem;
  border: 0;
  width: 100%;
  background: none;
  cursor: pointer;
}

.pageName {
  text-align: left;
  word-break: break-word;
}

.disabled {
  background-color: var(--card-cta-disabled-text-color);
  color: var(--card-text-secondary-color);
  cursor: default;
}

.selected {
  background-color: var(--card-cta-active-bg-color-alt);
}

.hiddenLabel {
  font-size: 1.4rem;
  color: var(--card-text-secondary-color);
  padding-right: 0.4rem;
}
