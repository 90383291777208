@import 'src/styles/imports';

$widget-library-height: calc(
  100vh - $page-container-top-padding - $nav-container-height
);

.widgetLibrary {
  z-index: $layer-widget-library;

  button {
    appearance: none;
    background: none;
    border: none;

    &:focus-visible {
      border-radius: 40px;
    }
  }

  .entryBtn {
    white-space: nowrap;
  }
  .widgetList {
    display: flex;
    flex-direction: column;
    gap: 2px;
    background-color: var(--card-bg-color);
    color: var(--card-text-color);
    border-radius: var(--card-border-radius);
    transition: background-color 0.3s;
    box-shadow: $shadow-xl;
    padding: 1rem;
    max-height: $widget-library-height;
    backdrop-filter: var(--card-backdrop-filter);

    @include customScrollbar;

    // mobile
    @media screen and (max-width: $mobile-layout-width) {
      align-items: flex-start;
      max-height: 100%;
      box-shadow: none;
      border-radius: 0;
      padding: 0;
      margin: 0px 0px 4rem 0px;
    }

    .widgetListItem {
      width: 100%;
      min-width: 24rem;
      list-style: none;
      line-height: 150%;
      padding: 1rem;
      font-size: var(--global-font-size);
      color: var(--card-text-color);
      border-radius: 12px;
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      white-space: nowrap;

      // mobile
      @media screen and (max-width: $mobile-layout-width) {
        gap: 20px;
        padding: 0.8rem 2rem;
        border-radius: 0;
        font-weight: 500;
      }

      &:hover {
        cursor: pointer;
        background-color: var(--card-active-bg-color);
      }

      &:focus-visible {
        border-radius: 12px;
        outline-offset: -2px;
      }
      svg {
        stroke: currentColor;
      }
    }
  }

  .previewPanel {
    @include gappedColumn(2rem);
    position: absolute;
    padding-top: 2rem;
    background-color: var(--card-bg-color);
    color: var(--card-text-color);
    border-radius: var(--card-border-radius);
    transition: background-color 0.3s;
    box-shadow: $shadow-xl;
    width: 30vw;
    min-width: 250px;
    cursor: default;
    text-wrap: wrap;
    align-items: center;
    overflow: hidden;
    z-index: $layer-widget-preview;

    &[data-show='false'] {
      display: none;
    }

    .descriptionText {
      font-size: 1.8rem;
      line-height: 2.4rem;
      font-weight: bold;
      text-align: center;
      padding: 0 2rem;
    }

    .imgContainer {
      display: flex;
      align-items: flex-end;
      width: 100%;
      aspect-ratio: 2.04 / 1;

      img {
        width: 100%;
      }
    }
  }
}
